import Image from 'next/image';
import React from 'react';

function HowItWorks() {
  return (
    <>
      <div className="row">
        <div className="col-lg-12 how-works-section">
          <h5 className="fs-50 fw-600 text-center">How it Works</h5>
          <div className="row font-sfpro pt-md-5 pt-3">
            <div className="col-lg-3 col-md-6 col-12 text-center px-md-5 mt-md-0">
              <div className="icon-box rounded-circle d-flex justify-content-center m-auto mb-md-3 mb-0">
                <img src="/images/home/how-it-work-01.svg" width={70} alt="" />
              </div>
              <p className="fs-20 label-color-2">
                Choose from a wide range of services offered directly to your doorstep.
              </p>
            </div>
            <div className="col-lg-3 col-md-6 col-12 text-center px-md-5 mt-md-0 mt-3 ">
              <div className="icon-box rounded-circle d-flex justify-content-center m-auto mb-md-3 mb-0">
                <img src="/images/home/how-it-work-02.svg" width={70} alt="" />
              </div>
              <p className="fs-20 label-color-2">Enter Your Pickup and Destination Location </p>
            </div>
            <div className="col-lg-3 col-md-6 col-12 text-center px-md-5 mt-md-0 mt-3 ">
              <div className="icon-box rounded-circle d-flex justify-content-center m-auto mb-md-3 mb-0">
                <img src="/images/home/how-it-work-03.svg" width={70} alt="" />
              </div>
              <p className="fs-20 label-color-2">Find/Browse anything with one click at nearby location</p>
            </div>
            <div className="col-lg-3 col-md-6 col-12 text-center px-md-5 mt-md-0 mt-3 ">
              <div className="icon-box rounded-circle d-flex justify-content-center m-auto mb-md-3 mb-0">
                <img src="/images/home/how-it-work-04.svg" width={70} alt="" />
              </div>
              <p className="fs-20 label-color-2">Pay money for your Order. Quick, Secure and Simple</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default HowItWorks;
